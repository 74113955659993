
import { ref, defineComponent } from 'vue'
import * as api from '../../utils/api'
import * as storage from '../../utils/storage'
import Menu from '../../components/menu/Hospital.vue'
import { useDialog } from 'naive-ui'
import axios from 'axios'
import { tags, jobStyle, workTypes } from '../../utils/const'
import { IApply, IApplyPhoto, IApplySalary } from '../../../interfaces/hospital'
type Rating = 'rating1' | 'rating2' | 'rating3' | 'rating4' | 'rating5' | 'rating6'

export default defineComponent({
	components: {
		Menu,
	},
	data() {
		const formRef = ref(null)
		const modelRef = ref({
			labelTag: '',
			hospitalName: '',
			postcode: '',
			location: '',
			type: 'veterinarian',
			description: '',
			prPoint: '',
			requiredQualification: '',
			jobStyle: 'fulltime',
			time: '',
			treatment: '',
			address: '',
			salary: ['annual'],
			annual: [] as string[],
			monthly: [] as string[],
			daily: [] as string[],
			hourly: [] as string[],
			note: '',
			annualDef: ['true'],
			monthlyDef: ['true'],
			dailyDef: ['true'],
			hourlyDef: ['true'],
			tags: [] as string[],
			intEnable: ['intEnable'],
		})
		const vStandard = (lengthLimit: number, required: boolean, value: string) => {
			if (!value && required) return new Error('必須の項目です')
			return value.length <= lengthLimit || new Error('20文字以内で入力してください')
		}
		const vsa = (lengthLimit: number, required: boolean) => {
			return [
				{
					required,
					validator(r: any, v: string) {
						return vStandard(lengthLimit, required, v)
					},
					trigger: ['input', 'blur'],
				},
			]
		}
		const vNum = [
			{
				required: true,
				validator(r: any, vr: any) {
					const v = JSON.parse(JSON.stringify(vr))
					if (!v[0].match(/^[0-9]+$/) || (v[1] && !v[1].match(/^[0-9]+$/))) return new Error('半角数字で入力してください。')
				},
				trigger: ['input', 'blur'],
			},
		]
		const showModalRef = ref(false)
		const previewImageUrlRef = ref('')
		function handlePreview(file: any) {
			const { url } = file
			previewImageUrlRef.value = url
			showModalRef.value = true
		}
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		return {
			formRef,
			model: modelRef,
			loading: false,
			monthly: false,
			annual: true,
			daily: false,
			hourly: false,
			success: false,
			addressLoading: false,
			admin: false,
			errorDialog,
			headers: {},
			actionUrl: '',
			err: '',
			editId: '',
			tags: tags(),
			js: jobStyle(),
			workTypes,
			rules: {
				labelTag: vsa(20, true),
				hospitalName: vsa(140, false),
				postcode: vsa(140, false),
				location: vsa(140, false),
				address: vsa(140, true),
				description: vsa(1000, true),
				prPoint: vsa(1000, true),
				requiredQualification: vsa(300, true),
				time: vsa(300, true),
				annual: vNum,
				monthly: vNum,
				daily: vNum,
				hourly: vNum,
				note: vsa(1000, false),
			},
			handlePreview,
			showModal: showModalRef,
			previewImageUrl: previewImageUrlRef,
			attachedFiles: ref([]) as any,
			logo: ref([]) as any,
		}
	},
	mounted: function () {
		document.title = '応募の新規作成 - Vets Index'
		api.refresh()
		const token = storage.getKeyItem('credential', 'accessToken')
		const role = storage.getKeyItem('credential', 'role')
		if(role === 'admin') this.admin = true
		this.headers = {
			Authorization: `Bearer ${token}`,
			Role: role
		}
		this.actionUrl = api.host + '/v1/upload'
		const fileNames = location.pathname.match(/\/([a-zA-Z0-9-]+)$/)
		if (!fileNames) return false
		const fileName = fileNames[1]
		const isCopy = !!location.pathname.match('apply_copy')
		if (fileName !== 'apply_new' && !isCopy) this.editId = fileName
		if (fileName !== 'apply_new') this.init(fileName)
	},
	methods: {
		init: async function (id: string) {
			try {
				const isCopy = !!location.pathname.match('apply_copy')
				const data = (await api.get(`/v1/hospital/apply/${id}`, false, true)) as IApply
				if(!isCopy) this.model.labelTag = data.labelTag
				this.model.hospitalName = data.hospitalName || ''
				this.model.address = data.address
				this.model.location = data.location
				this.model.type = data.type
				this.model.description = data.description
				this.model.prPoint = data.prPoint
				this.model.requiredQualification = data.requiredQualification
				this.model.treatment = data.treatment
				this.model.jobStyle = data.style
				this.model.time = data.time
				this.model.tags = data.tags as unknown as string[]
				const salary = data.salary as unknown as IApplySalary
				this.model.note = salary.note || ''
				this.model.annual = [salary.annual?.min.toString() || '0', salary.annual?.max ? salary.annual?.max.toString() : '0']
				if (salary.annual?.min) this.model.salary.push('annual')
				if (salary.annual?.definitive) this.model.annualDef = ['true']
				this.model.monthly = [salary.monthly?.min.toString() || '0', salary.monthly?.max ? salary.monthly?.max.toString() : '0']
				if (salary.monthly?.min) this.model.salary.push('monthly')
				if (salary.monthly?.definitive) this.model.monthlyDef = ['true']
				this.model.daily = [salary.daily?.min.toString() || '0', salary.daily?.max ? salary.daily?.max.toString() : '0']
				if (salary.daily?.min) this.model.salary.push('daily')
				if (salary.daily?.definitive) this.model.dailyDef = ['true']
				this.model.hourly = [salary.hourly?.min.toString() || '0', salary.hourly?.max ? salary.hourly?.max.toString() : '0']
				if (salary.hourly?.min) this.model.salary.push('hourly')
				if (salary.hourly?.definitive) this.model.hourlyDef = ['true']
				const attachedFile = data.attachedFiles as unknown as IApplyPhoto[]
				for (const a of attachedFile) {
					this.attachedFiles.push({
						id: a.url,
						name: a.url,
						status: 'finished',
						url: a.url,
						caption: a.caption,
					})
				}
				if (data.logo) {
					this.logo = {
						id: data.logo,
						name: data.logo,
						status: 'finished',
						url: data.logo,
					}
				}
			} catch (e: any) {
				this.errorDialog(e, () => true)
				console.error(e)
			}
		},
		post: async function (status: string) {
			const salary: any = {}
			salary.note = this.model.note || ''
			if (this.model.salary.includes('annual'))
				salary.annual = {
					min: parseInt(this.model.annual[0], 10),
					max: !!this.model.annualDef.length ? parseInt(this.model.annual[1], 10) : undefined,
					definitive: !!this.model.annualDef.length,
				}
			if (this.model.salary.includes('monthly'))
				salary.monthly = {
					min: parseInt(this.model.monthly[0], 10),
					max: !!this.model.monthlyDef.length ? parseInt(this.model.monthly[1], 10) : undefined,
					definitive: !!this.model.monthlyDef.length,
				}
			if (this.model.salary.includes('daily'))
				salary.daily = {
					min: parseInt(this.model.daily[0], 10),
					max: !!this.model.dailyDef.length ? parseInt(this.model.daily[1], 10) : undefined,
					definitive: !!this.model.dailyDef.length,
				}
			if (this.model.salary.includes('hourly'))
				salary.hourly = {
					min: parseInt(this.model.hourly[0], 10),
					max: !!this.model.hourlyDef.length ? parseInt(this.model.hourly[1], 10) : undefined,
					definitive: !!this.model.hourlyDef.length,
				}
			const attachedFiles = this.attachedFiles.map((i: any) => {
				return { url: i.url, caption: i.caption }
			})
			const logo = this.logo[0] ? this.logo[0].url : undefined
			const paramr = {
				labelTag: this.model.labelTag,
				hospitalName: this.model.hospitalName,
				address: this.model.address,
				location: this.model.location,
				type: this.model.type,
				description: this.model.description,
				prPoint: this.model.prPoint,
				requiredQualification: this.model.requiredQualification,
				treatment: this.model.treatment,
				style: this.model.jobStyle,
				time: this.model.time,
				salary,
				tags: JSON.parse(JSON.stringify(this.model.tags)),
				attachedFiles,
				logo,
                status
			}
			const param = {
				...paramr,
				admin: this.admin
			}
			try {
				const data = await api.post(this.editId ? `/v1/hospital/apply/edit/${this.editId}` : `/v1/hospital/apply/new`, param, true)
				this.$router.push('/hospital/apply_list')
			} catch (e: any) {
				this.errorDialog(e, () => true)
				console.error(e)
			}
		},
		postcodeToAddress: async function () {
			try {
				console.log('fired')
				const value = this.model.postcode
				if (!/^[0-9]{7}/.test(value)) return false
				this.addressLoading = true
				const data = await axios.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${value}`)
				const raw = data.data
				if (raw.message) return false
				const r = raw.results[0]
				this.model.location = `${r.address1}${r.address2}${r.address3}`
				this.addressLoading = false
			} catch (e: any) {
				console.error(e)
			}
		},
		logoFinish: function (e: any) {
			const json = JSON.parse(e.event.target.response)
			if (json.success) {
				const logo = this.logo
				logo.push({
					id: json.path,
					name: json.path,
					status: 'finished',
					url: json.cdn,
					caption: '',
				})
				this.logo = logo
			} else {
				this.errorDialog(json, () => true)
			}
		},
		attachedFileFinish: function (e: any) {
			const json = JSON.parse(e.event.target.response)
			if (json.success) {
				const attachedFile = this.attachedFiles
				attachedFile.push({
					id: json.path,
					name: json.path,
					status: 'finished',
					url: json.cdn,
					caption: '',
				})
				this.attachedFiles = attachedFile
			} else {
				this.errorDialog(json, () => true)
			}
		},
        lookCheck: function () {
            window.open(`/apply/${this.editId}`, '_blank')
        },
		onRemove: function(options: any) {
			const id = options.file.id
			const ret = []
			for(const value of this.attachedFiles) {
				if(value.id !== id) ret.push(value)
			}
			this.attachedFiles = ret
			return true
		}
	},
})
